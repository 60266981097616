import { Show } from "solid-js";
import cn from "classnames";
import { createSignal } from "solid-js";
import crypto from "crypto-js";
import { Input } from "#components/fields";
import { tagular } from "#cohesion/tagular";

const { SHA256, enc } = crypto;

interface NewsletterSignupProps {
  background?: "light" | "dark";
  btnText?: string;
  callback?: () => void;
  className?: string;
  errorText?: string;
  formName?: string;
  formType?: string;
  fullWidth?: boolean;
  inlineForm?: boolean;
  layoutForm?: "default" | "hero";
  successText?: string;
  title?: string;
  source: string;
}

export const NewsletterSignup = ({
  background = "light",
  btnText = "Sign Up",
  callback = () => {},
  className = "",
  errorText = "There was an error processing your request, please try again later.",
  formName = "NEWSLETTER-FORM",
  formType = "NEWSLETTER",
  fullWidth = true,
  inlineForm = false,
  layoutForm = "default",
  successText = "Thanks for signing up!",
  title = "",
  source,
}: NewsletterSignupProps) => {
  const [email, setEmail] = createSignal("");
  const [success, setSuccess] = createSignal(false);
  const [error, setError] = createSignal("");
  const [hasFocused, setHasFocused] = createSignal(false);

  const tagularPayload = {
    formContext: {
      formId: source,
      formName,
      formType,
    },
  };

  const handleFocus = () => {
    if (!hasFocused()) {
      tagular("FormStarted", tagularPayload);
      setHasFocused(true);
    }
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    const hashedEmail =
      email() !== "" ? SHA256(email().toLowerCase()).toString(enc.Hex) : null;

    const data = {
      source,
      email: email(),
      formName,
      formType,
    };

    try {
      const res = await fetch("https://www.lonelyplanet.com/api/newsletter", {
        method: "POST",
        body: JSON.stringify(data),
      });
      setError(res.status !== 200 ? errorText : "");
      setSuccess(res.status === 200);
      if (res.status === 200) {
        document.cookie = "newsletterSubscribed=true; max-age=31536000";
        setTimeout(() => {
          tagular("FormSubmitted", {
            field: [
              {
                fieldName: "userId",
                fieldValue: hashedEmail || "",
              },
            ],
            ...tagularPayload,
          });
        }, 3000);
        callback();
      }
    } catch (err: any) {
      setError(err.message);
    }
    return true;
  };

  const handleInput = (e: InputEvent) => {
    const target = e.target as HTMLInputElement;
    setEmail(target.value);
  };

  return (
    <div
      class={className}
      data-view-track
      data-tagular={JSON.stringify({
        event: "FormViewed",
        captureContext: {
          capturePlacement: "Inline",
          captureType: "Custom Form",
          identityRequested: false,
        },
        ...tagularPayload,
      })}
    >
      {title && !success() && (
        <h3
          class={cn("text-lg font-semibold", {
            "text-white": background === "dark",
            "text-blue": background === "light",
            "text-base": layoutForm === "hero",
          })}
        >
          {title}
        </h3>
      )}

      {error() && (
        <>
          {layoutForm === "default" && (
            <p
              class={cn({
                "text-white": background === "dark",
                "text-red": background === "light",
              })}
            >
              {errorText}
            </p>
          )}
          {layoutForm === "hero" && (
            <div
              class={cn({
                "text-white inline-block w-auto bg-red text-xs mt-2 p-1":
                  background === "dark",
                "text-red": background === "light",
              })}
            >
              <p
                class={cn({
                  "text-white": background === "dark",
                  "text-red": background === "light",
                })}
              >
                {errorText}
              </p>
            </div>
          )}
        </>
      )}

      {success() && (
        <>
          <h3
            class={cn("text-lg font-bold mt-4", {
              "text-white": background === "dark",
              "text-blue": background === "light",
            })}
          >
            {successText}
          </h3>
          <p
            class={cn({
              "text-white": background === "dark",
              "text-black-400": background === "light",
            })}
          >
            We just sent a confirmation email to <span>{email()}</span>
          </p>
        </>
      )}

      {!success() && (
        <form onSubmit={handleSubmit}>
          <label for="email" class="sr-only">Email address</label>
          <Input
            id="email"
            required
            type="email"
            value={email()}
            placeholder="Email address"
            onInput={handleInput}
            onFocus={handleFocus}
            className={cn("my-4 placeholder-black max-w-full h-10", {
              "w-full": fullWidth,
              "mb-0 w-full leading-none md:w-3/5": inlineForm,
            })}
            aria-label="Email address"
          />
          <Show
            when={inlineForm}
            fallback={
              <button type="submit" class="btn btn-primary mb-4 w-full" aria-label="Sign up for the newsletter">
                {btnText}
              </button>
            }
          >
            <button
              type="submit"
              class="btn btn-primary leading-none h-10 mt-4 mb-4 w-full ml-0 md:mt-0 md:ml-6 md:w-auto"
              aria-label="Sign up for the newsletter"
            >
              {btnText}
            </button>
          </Show>
          <p
            class={cn("text-xs", {
              "text-white": background === "dark",
              "text-black-400": background === "light",
            })}
          >
            Subscribe to Lonely Planet newsletters and promotions.{" "}
            <span class="whitespace-nowrap">
              Read our{" "}
              <a
                href="/legal"
                class={cn("underline", {
                  "text-white": background === "dark",
                  "text-blue": background === "light",
                })}
                aria-label="Read our Privacy Policy"
              >
                Privacy Policy.
              </a>
            </span>
          </p>
        </form>
      )}
    </div>
  );
};