import cn from "classnames";

export interface InputProps {
  className?: string;
  type?: "text" | "email" | "checkbox";
  placeholder?: string;
  name?: string;
  required?: boolean | null;
  id?: string;
  onInput: (e: InputEvent) => void;
  onFocus?: () => void;
  value: string;
}

export function Input(props: InputProps) {
  const {
    className = "",
    type = "text",
    placeholder = "",
    name = "",
    required = null,
    id = "",
    onInput,
    onFocus = () => {},
    value,
  } = props;

  return (
    <input
      id={id}
      required
      type={type}
      name={name}
      placeholder={placeholder}
      onInput={onInput}
      onFocus={onFocus}
      value={value}
      class={cn(
        "rounded-pill border border-black-200 focus:border-black-300 px-8 py-3 font-light",
        className,
      )}
    />
  );
}
